import styled from '@emotion/styled';
import { ifNotProp } from 'styled-tools';

type StyledLabelProps = {
    hasFocus?: boolean;
    isActive?: boolean;
    isValid?: boolean;
};

export const StyledLabel = styled.label<StyledLabelProps>(
    ({ theme, isActive, hasFocus }) => ({
        position: 'absolute',
        top: '1.4em',
        fontSize: theme.fontSizes[isActive ? 'xs' : 'md'],
        transition: '0.1s transform, 0.1s font-size',
        transform: `translateY(${isActive ? '-2.3em' : '-50%'})`,
        color: hasFocus ? theme.colors.formFieldActiveText : theme.colors.formFieldPlaceholder,
        backgroundColor: theme.colors.formFieldBackground,
        maxWidth: '100%',
        width: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        transformOrigin: 'left top',
        pointerEvents: 'none',
        zIndex: 2,
    }),
    ifNotProp('isValid', ({ theme }) => ({
        color: theme.colors.error,
    }))
);
