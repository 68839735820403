import React, { FormEventHandler, useRef, useState, useEffect } from 'react';
import { M110NewsletterSignupModule } from '~/lib/data-contract';
import { LegacyImage, InputField, RawHtml } from '$shared/components';
import { ModuleContainer } from '../ModuleContainer';
import {
    StyledForm,
    StyledM10nputFieldWrapper,
    StyledM10Button,
    StyledM110,
    StyledM110Content,
    StyledM110ImageWrapper,
} from './styled';
import { useTranslation } from '~/shared/utils/translation';
import { isSSR, useFrame, useFranchise } from '~/shared/utils';
import { domain, sfmcBody, sfmcPostUrl } from './constants/sfmcValues';
import { queriesIsolated } from '~/theme';
import { validationSchema } from './M110NewsletterSignup.schema';
import { useModule } from '~/templates';
import { SpotTextBlock } from '../TextBlock';

/**
 * Calls an endpoint at Salesforce Marketing Cloud's Email Studio via Web Collect. Documentation: https://help.salesforce.com/s/articleView?id=sf.mc_es_email_studio_web_collect.htm&type=5
 */

export type M110NewsletterSignupProps = M110NewsletterSignupModule;
export const M110NewsletterSignup = ({
    image,
    headline,
    subHeadline,
    subHeadlineUppercase,
    description,
    legalText,
    ...rest
}: M110NewsletterSignupProps) => {
    const { index } = useModule();
    const { translate } = useTranslation();
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { activeMarket } = useFranchise();
    const { data } = useFrame();
    const formRef = useRef<HTMLFormElement | null>(null);
    const { MID, Butiksnr, SubAction, b2b_debitor } = sfmcBody;
    const staticLinks = data?.staticLinks || {};
    const origin = (!isSSR && window.location.origin) || '';
    const thankYouUrl = origin + (staticLinks?.newsletterThankYouPage?.url || '');
    const notFoundUrl = origin + (staticLinks?.newsletterErrorPage?.url || '');

    const imageSize = {
        w: 1000,
        h: 600,
    };

    const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();

        const isValid = validationSchema.isValidSync({ email });

        if (isValid) {
            formRef.current?.submit();
            return;
        }

        setErrorMessage(translate('form.validation.email'));
    };

    useEffect(() => {
        setErrorMessage('');
    }, [email]);

    return (
        <ModuleContainer {...rest} spacingTop={index === 0 ? 'none' : undefined}>
            <StyledM110 index={index}>
                <StyledM110Content position="center" alignment="left">
                    <SpotTextBlock
                        headline={headline}
                        subheadline={subHeadline}
                        subHeadlineUppercase={subHeadlineUppercase}
                        html={description}
                        onAppearAnimation="none"
                    />
                    <StyledForm
                        action={sfmcPostUrl}
                        name="subscribeForm"
                        method="post"
                        ref={formRef}
                        onSubmit={onSubmit}
                    >
                        <input type="hidden" name="thx" value={thankYouUrl} />
                        <input type="hidden" name="err" value={notFoundUrl} />
                        <input type="hidden" name="MID" value={MID} />
                        <input type="hidden" name="Butiksnr" value={Butiksnr} />
                        <input type="hidden" name="b2b_debitor" value={b2b_debitor} />
                        <input
                            type="hidden"
                            name="ETSubscriberKey"
                            value={`${b2b_debitor}_${email}`}
                        />
                        <input
                            type="hidden"
                            name="ButikNavn"
                            value={`${domain}/${activeMarket?.countryKey}`}
                        />
                        <input type="hidden" name="SubAction" value={SubAction} />
                        {/* TODO: email validation (would make sense to implement alongside checkout form) */}
                        <StyledM10nputFieldWrapper>
                            <InputField
                                label={translate('form.label.email')}
                                placeholder={translate('form.placeholder.email')}
                                invalidMessage={errorMessage}
                                isActive={!!email}
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                name="Email Address"
                                type="mail"
                                id="email"
                            />
                        </StyledM10nputFieldWrapper>
                        <StyledM10Button
                            variant="primary"
                            children={translate('modules.newsletterSignup.subscribe')}
                        />
                    </StyledForm>
                    {legalText && <RawHtml html={legalText} />}
                </StyledM110Content>
                {image?.src ? (
                    <StyledM110ImageWrapper {...imageSize}>
                        <LegacyImage
                            {...image}
                            src={image.src}
                            cW={imageSize.w}
                            cH={imageSize.h}
                            layout="fill"
                            objectFit="cover"
                            sizes={`${queriesIsolated.xs} 100vw, ${queriesIsolated.sm} 50vw, ${queriesIsolated.md} 50vw, 57vw`}
                        />
                    </StyledM110ImageWrapper>
                ) : null}
            </StyledM110>
        </ModuleContainer>
    );
};
