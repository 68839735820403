import React from 'react';
import { StyledLabel } from './styled';

type LabelProps = React.InputHTMLAttributes<HTMLLabelElement>;

export type LabelFieldProps = Omit<LabelProps, 'value'> & {
    /**
     * Label text content
     */
    label?: string;

    /**
     * Set styling to indicate input is invalid.
     */
    isValid?: boolean;

    /**
     * Set styling to indicate input is in focus.
     */
    hasFocus?: boolean;

    /**
     * When active the label will move up above the actual input field
     */
    isActive?: boolean;
};
export const Label = StyledLabel;
