import styled from '@emotion/styled';
import { Button, TextBlock } from '~/shared/components';
import { breakpoints } from '~/theme';
import { getClampFullRange } from '~/theme/utils/getClampValue';
import { AlignContent } from '../AlignContent';

export const StyledM110 = styled.div<{ index: number }>(({ index, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.gridConfig.gap,

    [breakpoints.xs]: {
        borderTop: theme.traits.line.asBorderStyle,
    },
    [breakpoints.sm]: {
        display: 'grid',
        padding: `0 ${theme.gridConfig.gutter}`,
        gridTemplateColumns: `repeat(${theme.gridConfig.columnCount.xs}, 1fr)`,
        borderTop: index === 0 ? 'none' : theme.traits.line.asBorderStyle,
        borderBottom: theme.traits.line.asBorderStyle,
    },
    [breakpoints.md]: {
        gridTemplateColumns: `repeat(${theme.gridConfig.columnCount.md}, 1fr)`,
    },
}));

export const StyledM110Content = styled(AlignContent)(
    ({ theme: { gridConfig, spaces, spacesRaw, colors, fontSizes, letterSpacings } }) => ({
        padding: `${getClampFullRange(spacesRaw[5], spacesRaw[6])} 0`,
        [breakpoints.xs]: {
            padding: `${spaces[5]} ${gridConfig.gutter} 0`,
        },
        [breakpoints.sm]: {
            gridColumn: '1 / span 3',
            paddingRight: `calc(4.15vw - ${gridConfig.gap})`,
        },
        [breakpoints.md]: {
            gridColumn: '2 / span 5',
            gap: spaces[3],
        },
        [breakpoints.lg]: {
            gridColumn: '2 / span 4',
        },
        [`${TextBlock}`]: {
            p: {
                color: colors.dark70,
                fontSize: fontSizes.xs,
                lineHeight: fontSizes.sm,
                letterSpacing: letterSpacings.xs,
                maxWidth: '70ch',
            },
        },
    })
);

export const StyledM110ImageWrapper = styled.div<{ w: number; h: number }>(({ theme, w, h }) => ({
    aspectRatio: `${w} / ${h}`,
    width: '100%',
    [breakpoints.sm]: {
        gridColumn: '4 / span 3',
        height: '100%',
        width: `calc(100% + ${theme.gridConfig.gutter})`,
    },
    [breakpoints.md]: {
        gridColumn: '7 / span 6',
    },
    [breakpoints.lg]: {
        gridColumn: '6 / span 7',
    },
}));

export const StyledForm = styled.form(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[3],

    [breakpoints.xs]: {
        width: '100%',
    },
    [breakpoints.sm]: {
        gap: theme.spaces[5],
        paddingTop: theme.spaces[3],
    },
}));

export const StyledM10Button = styled(Button)({
    alignSelf: 'flex-start',
});

export const StyledM10nputFieldWrapper = styled.div({
    width: 350,
    maxWidth: '100%',
});
